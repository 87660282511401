import React from 'react';

function Themes() {
  return (
    <main className="main-content">
      <h2>Download Themes</h2>
      <p>Hajira : <a className="animated-button" href="hajira.zip" download="hajira.zip">Download</a></p>
    </main>
  );
}

export default Themes;