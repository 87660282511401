import React from "react";
export default ()=>{

return (


<div className="content-wrapper">

<h1>Contact Me</h1>
<p>Dear visitor, i have made a profile on peopleperhour.com to skillup my carrier and boost my connects. 
    If you are interested to checkout my service, reach to me. I will have nice conversaton.   </p>

<div className="contact">
<div>
    <a href="https://pph.me/pkdev " target="_blank">Get In Touch</a>
</div>
</div>



</div>
);

}